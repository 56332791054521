* :focus {
  outline: 2px solid rgb(206, 206, 206);
}

html, body {
  background-color: black;
  color: #bebebe;
  font-family: testfont, sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: #e1e1e1;
  text-decoration: none;  
}

a:hover {
  color: #ffffff;
  text-decoration: none;
}


/* scrollbar  */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 4px;
}

:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 4px;
}


